body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.columna {
  float: left;
  width: 400px;
  padding: 10px;
  box-sizing: border-box;
}

.columnb {
  float: left;
  width: calc(100% - 400px);
  padding: 10px;
  box-sizing: border-box;
}

.cimg {
  width: calc(100% - 400px);
  height: 200px;
  margin-left: 0px;
  margin-right: 0px;
  float: right;
}

.cimg .blur {
  position: absolute;
  width: calc(100% - 400px);
  height: 200px;
  background-size: cover;
  background-position: center;
  background-image: url("https://bikus.org/Images/Fun/BotDidThing.jpeg");  
  background-repeat: repeat;
  background-attachment: fixed;
  filter: blur(10px);
}
  
.cimg img {
  position: relative;
  z-index: 1;
  object-fit: contain;
  object-position: center;
  padding-left: calc(50% - 100px);
  padding-right: calc(50% - 100px);
}


/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1000px) {
  .cimg {
    display: inline-block; 
    width: 100%;
    float: none;
  }

  .cimg .blur {
    width: 100%;
  }
}

.imgreal {
  filter: blur(0px);
}

@media screen and (max-width: 1000px) {
  .columna  {
    width: 100%;
  }
  .columnb  {
    width: 100%;
  }
}